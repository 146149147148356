import Vue from 'vue'
import VueRouter from 'vue-router'
import vuex from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("@/modules/dashboard/HomeView.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/modules/authenticate/LogIn.vue"),
    meta: {requiresAuth: false}
  },
  {
    path: "/usuarios",
    name: "UserCreate",
    component: () => import("@/modules/users/CreateUser.vue"),
    meta: {requiresAuth: true, guardName: 'Admin'}
  },
  {
    path: "/clientes",
    name: "ClientCreate",
    component: () => import("@/modules/clients/CreateClient.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/productos",
    name: "ProductoCreate",
    component: () => import("@/modules/productos/ConsultarProductos.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/consultar-cliente",
    name: "ClientManage",
    component: () => import("@/modules/clients/ConsultarClient.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/solicitar-prestamo",
    name: "SolicitarPrestamo",
    component: () => import("@/modules/prestamos/SolicitarPrestamo.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/listar-prestamos",
    name: "ListarPrestamos",
    component: () => import("@/modules/prestamos/ListarPrestamos.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/recibos",
    name: "ListarPrestamos",
    component: () => import("@/modules/recibos/ListarRecibos.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/registrar-pagos",
    name: "RegistrarPagos",
    component: () => import("@/modules/pagos/RegistrarPagos.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/analisis-prestamo",
    name: "AprobarRechazarPrestamo",
    component: () => import("@/modules/prestamos/AprobarRechazarPrestamo.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/simular-prestamo",
    name: "SimularCuota",
    component: () => import("@/modules/simulador/SimularCuota.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/apertura-caja",
    name: "AperturaCaja",
    component: () => import("@/modules/caja/CajaGeneral.vue"),
    meta: {requiresAuth: true, guardName: ['Admin', 'Analista']}
  },
  {
    path: "/configuracion",
    name: "AppConfig",
    component: () => import("@/modules/configs/ProjectConfig.vue"),
    meta: {requiresAuth: true, guardName: ['Admin']}
  },
  {
    path: '*',
    name:'404',
    component: () => import('@/modules/not-found/Page404.vue'),
    meta: {requiresAuth: true}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  
  if (!to.meta.requiresAuth) return next();
  if (to.meta.requiresAuth) {
    if(!vuex.state.token) {
      return next({name: 'Login'});
    }
    const guard = to.meta.guardName;
    if(guard) {
      if(Array.isArray(guard)) {
        if(guard.includes(vuex.state.role)) {
          return next();
        }else {
          return next({name: '404'})
        }
      }else {
        if(guard !== vuex.state.role) {
          return next({name: '404'})
        } else {
          return next();
        }
      }
      
    }
    return next();
    
  }

});

export default router
