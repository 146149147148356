import axios from "axios";
import router from "@/router";
import vuex from "@/store";
import config from "./config"
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  },
};
const authenticate = {
  login(documento, password) {
    return new Promise((resolve, reject) => {
      if (!documento || !password) {
        reject(new Error("Empty Username/Password not allowed"));
        return;
      }

      local.apiInstance
        .post("/login", {
          documento,
          password,
        })
        .then( async (response) => {
          // Commit state authenticated vuex
          vuex.commit("token", response.data.token);
          vuex.commit("user", response.data.user);
          if(response.data.user.roles === null || response.data.user.roles.length === 0) {
            vuex.commit("role", 'General');
          } else {
            vuex.commit("role", response.data.user.roles[0].name);
          }
          // router push to home
          const configuracion = await config.getResources();
          vuex.commit("setConfig", configuracion.configuracion[0]);
          router.push("/");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout() {
    return new Promise((resolve, reject) => {

      local.apiInstance
        .delete("/logout", {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then(() => {
          // Commit state authenticated vuex
          vuex.commit("desactivar");
          // router push to home
          router.push("/login");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default authenticate;
