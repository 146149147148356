import axios from "axios";

import vuex from "@/store";
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: `${process.env.VUE_APP_BASE_URL}/config`,
    });
  },
};
const clients = {
  update(payload){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .patch('/actualizar', payload, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getResources() {
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get('', {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default clients;
